<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防巡检内容'"
    >
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/firePatrolContent",
      searchVal: "",
      searchQuery: {},
      props: [
        {
          label: "标题",
          prop: "title",
          align: "center",
          width: "200",
        },
        {
          label: "内容",
          prop: "content",
          align: "center",
        },

        {
          label: "创建时间",
          prop: "createtime",
          align: "center",
          width: "200px",
        },
      ],

      formProps: [
        {
          label: "标题",
          prop: "title",
          type: "input",
        },
        {
          label: "内容",
          prop: "content",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
